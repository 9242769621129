<template>
  <div>
    <div class="flex_center">
      <div class="acc_border">
        <div class="title"><i class="iconfont icon-gongsi1"></i>公司LOGO</div>
        <div class="acc_img_box" v-if="accObj.logo"><img :src="accObj.logo" alt="logo" /></div>
        <div class="acc_no_img" v-else><img src="@/assets/img/account/no_logo.png" alt="logo" /></div>
        <div class="acc_tip upload__tip" slot="tip">仅支持JPG、PNG格式，文件小于5M(方形图)</div>
        <el-upload ref="imgUpload" :file-list="fileList" :on-remove="handleRemove" action="fakeaction" :limit="1" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" :on-change="addFile"
          class="upload_box" :http-request="handleUploadFile" :on-success="handleSuccess" list-type="picture">
          <div size="small" type="primary" class="button_show m_center" @click="uploadImg()">上传LOGO</div>
        </el-upload>
      </div>
      <!-- <div class="acc_border">
                <div class="title"><i class="iconfont icon-xingming"></i>昵 称</div>
                <div class="acc_inpt"><el-input  v-model="accObj.nickname"></el-input></div>
                <div class="button_show m_center">修改昵称</div>
            </div> -->
      <div class="acc_border">
        <div class="title title_bottom"><i class="iconfont icon-mima"></i>密 码</div>
        <!-- <div class="acc_inpt">
          <el-input v-model="accObj.password" type="password"></el-input>
        </div> -->
        <div class="button_show m_center" @click="passwordDialog=true">修改密码</div>
      </div>
      <div class="acc_border">
        <div class="title"><i class="iconfont icon-jurassic_add-users"></i>角 色</div>
        <div class="acc_check">
          <el-checkbox v-model="supplyRoleId1" @change="changeCheck(supplyRoleId1,1),supplyRoleId2=!supplyRoleId1" :disabled='(accObj.supplyRoleId?true:(!supplyRoleId1&&chechboxFlag)) || userInfo.roleId==2' label="供应商" />
          <el-checkbox v-model="enterRoleId1" @change="changeCheck(enterRoleId1,3)" :disabled='(accObj.enterRoleId?true:(!enterRoleId1&&chechboxFlag)) || userInfo.roleId==2' label="核心企业" />
          <el-checkbox v-model="manageRoleId1" @change="changeCheck(manageRoleId1,2)" :disabled='accObj.manageRoleId?true:(!manageRoleId1&&chechboxFlag)' label="资 方" />
        </div>
        <div class="button_show m_center" @click="getOtherRole">申请角色</div>
      </div>
    </div>

    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码" :visible.sync="passwordDialog" center class="dialog_width500" :close-on-click-modal="false">
      <el-form :model="passForm" :rules="passRules" ref="passForm" class="pass_form">
        <el-form-item label="原密码:" prop="oldPassword">
          <el-input v-model="passForm.oldPassword" :type="type1">
            <i slot="suffix" class="iconfont icon-yanjing" @click="inputType1 = !inputType1" autocomplete="auto"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="password">
          <el-input v-model="passForm.password" :type="type2">
            <i slot="suffix" class="iconfont icon-yanjing" @click="inputType2 = !inputType2" autocomplete="auto"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="confirmPassword">
          <el-input v-model="passForm.confirmPassword" :type="type3">
            <i slot="suffix" class="iconfont icon-yanjing" @click="inputType3 = !inputType3" autocomplete="auto"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancelForm()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitPass('passForm')">确 认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { loginOut, editUser, getUserInfo, uploads } from "@/api/api.js";
import { changePassword, getOtherRole } from "@/api/centerPerson.js";
export default {
  data() {
    var validPassword = (rule, value, callback) => {
      if (value !== '') {
        let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/;
        if (!reg.test(value)) {
          callback(new Error('密码为数字,大小写字母,特殊符号 至少包含三种,长度为8-30位'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    //两次密码一致性校验
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.passForm.password) {
        callback(new Error('输入新密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      roleIdList: [{ supplyRoleId1: '' }, { enterRoleId1: '' }, { manageRoleId1: '' }],
      supplyRoleId1: false,
      enterRoleId1: false,
      manageRoleId1: false,
      chechboxFlag: false,
      accObj: {
        logo: '',//require('@/assets/img/account/logo1.png'),
        // nickname:'zhanggsan_2021',
        password: '',
        roleIdList: [],
        supplyRoleId: '',
        enterRoleId: '',
        manageRoleId: '',
        roleId: []
      },
      passForm: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
      passRules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }, { validator: validPassword, trigger: "blur" }],
        confirmPassword: [{ required: true, validator: validatePass, trigger: 'blur' },],
      },
      passwordDialog: false,
      userId: '',
      fileList: [],
      inputType1: false,//查看密码
      inputType2: false,//查看密码
      inputType3: false,//查看密码
      userInfo: {},
      userid: '',
      uploadFrom: {}
    }
  },
  created() {
    this.getUserInfo()
  },
  computed: {
    type1() {
      return this.inputType1 ? "text" : "password";
    },
    type2() {
      return this.inputType2 ? "text" : "password";
    },
    type3() {
      return this.inputType3 ? "text" : "password";
    }
  },
  methods: {
    //userId查询获取基础信息
    async getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
      this.userId = this.userInfo.userId
      let res = await getUserInfo('userId=' + this.userId)
      if (res && res.code == 200) {
        this.accObj = res.data
        this.accObj.supplyRoleId ? this.supplyRoleId1 = true : this.supplyRoleId1 = false
        this.accObj.enterRoleId ? this.enterRoleId1 = true : this.enterRoleId1 = false
        this.accObj.manageRoleId ? this.manageRoleId1 = true : this.manageRoleId1 = false
        // console.log()
        // this.accObj.password=this.accObj.password.slice(0,9)
      }
      else {
        this.$message.error(res.msg)
      }
    },

    //修改密码
    async changePassword() {
      let res = await changePassword('newPassword=' + this.passForm.password + '&password=' + this.passForm.oldPassword)
      if (res && res.code == 200) {
        this.passwordDialog = false
        this.logOut()
      }
      else {
        this.$message.error(res.msg)
      }
    },

    //修改用户信息
    async register() {
      this.accObj.isValid = '-1'
      // console.log(this.accObj.password)
      let res = await editUser(this.accObj)
      if (res && res.code == 200) {
        this.$message.success(res.msg)
        this.passwordDialog = false
      }
      else {
        this.$message.error(res.msg)
      }
    },
    //登出
    async logOut() {
      let res = await loginOut()
      if (res && res.code == 200) {
        this.$router.push({ name: 'Dashboard', params: { popLoginForm: true } })
      }
    },
    //上传图片
    async handleUploadFile(params) {
      this.handleRemove()
      const file = params.file
      let form = new FormData();
      form.append("files", file);
      uploads(form).then(res => {
        this.accObj.headPort = res[0]
        this.register()
      })
    },
    addFile(file, fileList) {
      this.fileList = fileList;
      //限制上传文件为5M
      var sizeIsSatisfy = file.size < 5 * 1024 * 1024 ? true : false;
      if (sizeIsSatisfy) {
        return true;
      } else {
        // this.fileSizeIsSatisfy = false;
        return false;
      }
    },
    //删除图片
    handleRemove() {
      this.accObj.logo = ''
      this.fileList = []
    },
    //取消
    cancelForm() {
      this.$refs.passForm.resetFields()
      this.passwordDialog = false
    },
    //修改密码
    submitPass(passForm) {
      this.$refs[passForm].validate((valid) => {
        if (valid) {
          this.changePassword()
        } else {
          return false;
        }
      });
    },
    changeCheck(val, id) {
      if (val) {
        this.chechboxFlag = true
        let roleId = ''
        id == 1 ? roleId = '1' : (id == 2 ? roleId = '2' : roleId = '3')
        this.roleId = roleId
      } else {
        this.chechboxFlag = false
      }
      this.getUserInfo()
      console.log(val, id)
    },
    //修改角色
    async getOtherRole() {
      let res = await getOtherRole('roleId=' + this.roleId)
      if (res && res.code == 200) {
        this.$message.success(res.msg)
      }
      else {
        this.$message.error(res.msg)
      }
    },
  }
}
</script>
<style scoped>
  .limit_width {
    margin-bottom: 100px !important;
  }
  .acc_border {
    width: 470px;
    height: 290px;
    padding: 30px 35px 40px;
    background-image: url(../../../../assets/img/account/acc_set_bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 14px;
    margin-left: 45px;
  }
  .acc_img_box {
    width: 86px;
    height: 86px;
    line-height: 86px;
    text-align: center;
    margin: 20px auto 10px;
    border-radius: 50%;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(17, 47, 46, 0.24) inset;
  }
  .acc_img_box img {
    width: 66px;
  }
  .acc_no_img {
    width: 86px;
    height: 86px;
    margin: 20px auto 10px;
  }
  .iconfont {
    margin-right: 10px;
  }

  .title {
    color: #339c9b;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
  .title_bottom {
    margin-bottom: 158px;
  }
  .acc_tip {
    text-align: center;
    font-size: 12px;
    color: #92a2bc;
    line-height: 16px;
    margin-bottom: 24px;
  }
  .m_center {
    margin: auto;
  }
  .acc_check {
    margin: 48px auto 84px;
  }
  .acc_inpt {
    margin: 48px auto 68px;
  }
  .acc_inpt >>> .el-input__inner {
    text-align: center;
  }
  .pass_form >>> .el-form-item.is-required {
    padding-left: 14px;
  }
  .pass_form >>> .el-form-item {
    margin-bottom: 20px;
  }
  .dialog_width500 >>> .el-form-item__label {
    min-width: 84px;
    width: 24.338%;
  }
  .upload_box {
    text-align: center;
  }
  .dialog_width500 /deep/ .icon-yanjing {
    font-size: 16px;
    color: #92a2bc;
    position: absolute;
    width: 60px;
    right: 0;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-right: 0;
  }
  .acc_check >>> .el-checkbox-group {
    display: flex;
    justify-content: center;
  }
  .acc_check >>> .el-checkbox {
    margin-right: 22px;
    margin-left: 22px;
  }
  .acc_check >>> .el-checkbox__label {
    color: #162747;
    font-size: 16px;
  }
  .acc_check >>> .el-checkbox__input.is-checked .el-checkbox__inner,
  .acc_check >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #ffffff;
    border: 1px solid #e1e7ea;
    color: #339c9b;
  }
  .acc_check >>> .el-checkbox__inner::after {
    width: 6px;
    height: 14px;
    border-width: 2px;
    border-color: #339c9b;
    left: 6px;
    top: -1px;
  }
  .acc_check >>> .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #ffffff;
  }
  @media screen and (max-width: 1664px) {
    .dialog_width500 /deep/ .icon-yanjing {
      font-size: 12px;
      width: 40px;
      height: 26px;
      line-height: 26px;
      transform: scale(0.9);
      margin-right: -5px;
    }
    .acc_border {
      width: 310px;
      height: 191px;
      padding: 20px 23px 26px;
      border-radius: 9px;
      margin-left: 30px;
    }
    .pass_form >>> .el-form-item.is-required {
      padding-left: 9px;
    }
    .pass_form >>> .el-form-item {
      margin-bottom: 26px;
    }
    .title {
      font-size: 13px;
      line-height: 17px;
    }
    .title_bottom {
      margin-bottom: 105px;
    }
    .acc_check {
      margin: 32px auto 51px;
    }
    .acc_inpt {
      margin: 32px auto 47px;
    }
    .acc_no_img {
      width: 57px;
      height: 57px;
      margin: 13px auto 7px;
    }
    .acc_tip {
      font-size: 12px;
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
      line-height: 12px;
      margin-bottom: 16px;
    }
    .acc_img_box {
      width: 57px;
      height: 57px;
      line-height: 57px;
      margin: 13px auto 7px;
      box-shadow: 0px 0px 2px 0px rgb(17 47 46 / 24%) inset;
    }
    .acc_img_box img {
      width: 44px;
    }
    .acc_check >>> .el-checkbox__label,
    .acc_check >>> .el-checkbox__input.is-checked + .el-checkbox__label {
      font-size: 12px;
    }
    .acc_check >>> .el-checkbox {
      margin-right: 12px;
      margin-left: 12px;
    }
    .acc_check >>> .el-checkbox__inner::after {
      width: 4px;
      height: 9px;
      left: 4px;
      top: 0px;
      border-width: 1px;
    }
  }
</style>