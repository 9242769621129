//个人中心
// 引入封装好的axios
import axios,{ apiUrl }  from "./request";
// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
// const api = process.env.NODE_ENV == "development" ? '/devapi' :'https://www.o2c-scf.com/api'
const api = process.env.NODE_ENV == "development" ? '/devapi' : apiUrl()

//查看我的通知列表
export const findUserNotiList = (params)=>{
    return axios.post(api + '/personal/findUserNotificationList?'+ params)
} 
//查看我的通知详情
export const findUserNotiInfoById = (params)=>{
    return axios.post(api + '/personal/findUserNotificationInfoById?'+ params)
} 
//删除我的通知
export const delUserNotiInfoById = (params)=>{
    return axios.post(api + '/personal/delUserNotificationInfoById?'+ params)
} 
//查看个人中心合同配置列表
export const findContConfigList = (params)=>{
    return axios.post(api + '/personal/findContractConfigList?'+ params)
}
//编辑合同配置
export const editContConfig = (a,d,b,c,params)=>{
    // return axios.post(api + '/personal/editContractConfig?'+ ty)
    return axios.post(api + '/personal/editContractConfig?contractName='+a+'&&version='+d+'&&id='+b+'&&contractDesc='+c , params, { type: "upload" })
}
//删除合同配置
export const delConConfigInfoById = (params)=>{
    return axios.post(api + '/personal/delContractConfigInfoById?'+ params)
}
//个人中心我的评级
export const getMyRateGrade = (params)=>{
    return axios.post(api + '/personal/getMyGrade?' + params)
}
//修改密码
export const changePassword = (params)=>{
    return axios.post(api + '/sys/changePassword?' + params)
}
//申请成为其他角色
export const getOtherRole = (params)=>{
    return axios.post(api + '/personal/applyOtherRole?' + params)
}